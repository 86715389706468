import React from 'react';
import GatsbyImg from 'gatsby-image';
import classnames from 'classnames';
import styles from './Ticker.module.scss';

const Ticker = ({ tickerIcon, tickerText }) => {
    return (
        <div className={styles.Ticker}>
            <div className={styles.IconWrapper}>
                <GatsbyImg fluid={tickerIcon.fluid} />
            </div>
            <div className={styles.WindowWrapper}>
                <span className={styles.Window}>
                    <div className={styles.Ribbon1}>0 1 2 3 4 5</div>
                </span>
                <span className={styles.Window}>{renderNumbers(2)}</span>
                <span className={(classnames(styles.Window), styles.Comma)}>
                    ,
                </span>
                <span className={styles.Window}>{renderNumbers(3)}</span>
                <span className={styles.Window}>{renderNumbers(4)}</span>
                <span className={styles.Window}>{renderNumbers(5)}</span>
            </div>
            <div className={styles.TickerText}>{tickerText}</div>
        </div>
    );
};

const renderNumbers = (repeatCount) => {
    let numbersList = '';

    for (let i = 0; i < repeatCount; i++) {
        numbersList += '0 1 2 3 4 5 6 7 8 9 0';
    }
    return <div className={styles.Ribbon1}>{numbersList}</div>;
};

export default Ticker;
