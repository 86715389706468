import React from 'react';
import GatsbyImg from 'gatsby-image';
import styles from './DesktopNews.module.scss';
import Button from 'aphrodite-shared/ui/Button/Button';
import {
    BasicDoughnut,
    BasicCircle,
    BasicSquare
} from '../../../../../SVGComponents/shapes';
import { getContenthubArticleFullSlug } from '../../../../../components/ContentHub/ContentHubHelper';

const DesktopNews = ({ blogs, ctaText, ctaLink, heading, subheading }) => {
    return (
        <div className={styles.News}>
            <BasicCircle
                className={styles.BasicLondonCircle}
                color="london75"
            />
            <BasicDoughnut className={styles.BasicDoughnut} color="london" />
            <BasicSquare className={styles.BasicSquare} color="london75" />
            <BasicDoughnut
                className={styles.BasicDenimDoughnut}
                color="denim10"
            />

            <h2 className={styles.Heading}>{heading}</h2>
            <div className={styles.Divider}></div>
            <p className={styles.Subheading}>{subheading}</p>
            <div className={styles.BlogArticles}>
                {blogs.map((blog) => (
                    <BlogArticle blog={blog} />
                ))}
            </div>
            <Button
                href={ctaLink}
                colour="secondary"
                dataGaId="aboutus__insights"
                className={styles.CTA}>
                {ctaText}
            </Button>
        </div>
    );
};

const BlogArticle = ({ blog }) => {
    return (
        <a
            href={getContenthubArticleFullSlug(
                blog.categoryTag.categoryTag,
                blog.slug
            )}>
            <div className={styles.BlogArticle}>
                <GatsbyImg
                    fluid={blog.articleHeaderImage.fluid}
                    className={styles.NewsImage}
                />
                <h5>{blog.displayName}</h5>
            </div>
        </a>
    );
};
export default DesktopNews;
