import React from 'react';
import styles from './MobileNews.module.scss';
import Button from 'aphrodite-shared/ui/Button/Button';
import Chevron from '../../../../../SVGComponents/Chevron';
import { getContenthubArticleFullSlug } from '../../../../../components/ContentHub/ContentHubHelper';

const MobileNews = ({ blogs, ctaText, ctaLink, heading, subheading }) => {
    return (
        <div className={styles.News}>
            <h2 className={styles.Heading}>{heading}</h2>
            <div className={styles.Divider}></div>
            <p className={styles.Subheading}>{subheading}</p>
            <div className={styles.BlogArticles}>
                {blogs.map((blog) => (
                    <a
                        href={getContenthubArticleFullSlug(
                            blog.categoryTag.categoryTag,
                            blog.slug
                        )}
                        className={styles.BlogButton}
                        data-ga-id="wantToKnowMore__question">
                        <p>{blog.displayName}</p>
                        <Chevron className={styles.Chevron} />
                    </a>
                ))}
            </div>
            <Button
                href={ctaLink}
                colour="secondary"
                dataGaId="aboutus__insights"
                className={styles.CTA}>
                {ctaText}
            </Button>
        </div>
    );
};

export default MobileNews;
