import React from 'react';
import DesktopNews from './DesktopNews/DesktopNews';
import MobileNews from './MobileNews/MobileNews';

const News = (data) => {
    return (
        <div>
            <DesktopNews {...data} />
            <MobileNews {...data} />
        </div>
    );
};

export default News;
