import React from 'react';
import GatsbyImg from 'gatsby-image';
import {
    WedgedArch,
    PeopleArch,
    LongArch
} from '../../../../SVGComponents/shapes';
import styles from './OurMessage.module.scss';
import ReactMarkdown from 'react-markdown';

const OurMessage = ({ heading, copy, businessTeam, image }) => {
    return (
        <div className={styles.OurMessage}>
            <div className={styles.LeftPane}>
                <div className={styles.ContentContainer}>
                    <h3 className={styles.Heading}>{heading}</h3>
                    <div className={styles.Divider}></div>
                    <ReactMarkdown source={copy} />
                </div>
            </div>
            <div className={styles.RightPane}>
                <div className={styles.ImageContainer}>
                    <div className={styles.BubbleDescription}>
                        <div className={styles.Description}>
                            <p>{businessTeam.heading}</p>
                            <h5>{businessTeam.subheading}</h5>
                        </div>
                        <PeopleArch
                            className={styles.PeopleArch}
                            color="denim65"
                        />
                    </div>

                    <GatsbyImg fluid={image.fluid} className={styles.image} />
                </div>
            </div>
            <WedgedArch className={styles.WedgedArch} size="sm" />
            <div className={styles.NameTag}>
                <p className={styles.NameText}>James and Christoph</p>
                <LongArch
                    rotate="right"
                    size="sm"
                    className={styles.coralArch}
                />
                <PeopleArch
                    color="denim45"
                    size="lg"
                    className={styles.basicArch}
                />
            </div>
        </div>
    );
};

export default OurMessage;
