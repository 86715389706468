import React from 'react';
import GatsbyImg from 'gatsby-image';
import { BasicDoughnut, PeopleArch } from '../../../../SVGComponents/shapes';
import Ticker from './Ticker/Ticker';
import styles from './AboveTheFold.module.scss';

const AboveTheFold = ({
    heading,
    copy,
    image,
    businessOwner,
    tickerIcon,
    tickerText
}) => {
    return (
        <div className={styles.AboveTheFold}>
            <BasicDoughnut
                className={styles.BasicDesktopDoughnut}
                color="denim65"
            />
            <BasicDoughnut
                className={styles.BasicMobileDoughnut}
                color="london95"
            />

            <div className={styles.LeftPane}>
                <div className={styles.ContentContainer}>
                    <h1 className={styles.Heading}>{heading}</h1>
                    <p className={styles.Copy}>{copy}</p>
                </div>
            </div>
            <div className={styles.RightPane}>
                <div className={styles.ImageContainer}>
                    <div className={styles.BubbleDescription}>
                        <PeopleArch className={styles.PeopleArchCoral} />
                        <div className={styles.Description}>
                            <h5>{businessOwner.heading}</h5>
                            <p>{businessOwner.subheading}</p>
                        </div>
                    </div>
                    <GatsbyImg fluid={image.fluid} className={styles.image} />
                    <Ticker tickerIcon={tickerIcon} tickerText={tickerText} />
                </div>
            </div>
        </div>
    );
};

export default AboveTheFold;
