import React from 'react';
import Footer from '../../../components/Footer/Footer';
import { graphql } from 'gatsby';
import AboveTheFold from './AboveTheFold/AboveTheFold';
import OurMessage from './OurMessage/OurMessage';
import FoundersStory from './FoundersStory/FoundersStory';
import TheTeam from './TheTeam/TheTeam';
import News from './News/News';
import WantToKnowMore from '../common/WantToKnowMore/WantToKnowMore';
import Questions from '../common/Questions/Questions';
import MetaData from '../../../components/Layout/MetaData.jsx';
import NavBar from '../../../components/NavBar/NavBar';

import styles from './AboutUs.module.scss';

const AboutUs = ({ data }) => {
    const { contentfulPageAboutUs: pageData } = data;

    const {
        titleTag,
        metaDescription,
        allowSearchEngineScraping,
        url
    } = pageData;

    const businessOwner = pageData.aboutUsTeam[0];
    const businessTeam = pageData.aboutUsTeam[1];

    return (
        <div className={styles.AboutUs}>
            <MetaData
                metaData={{
                    titleTag,
                    metaDescription,
                    allowSearchEngineScraping,
                    url
                }}
            />
            <NavBar />
            <AboveTheFold
                heading={pageData.aboveTheFoldHeading}
                copy={pageData.aboveTheFoldCopy.aboveTheFoldCopy}
                image={pageData.aboveTheFoldImage}
                businessOwner={businessOwner}
                tickerIcon={pageData.counterIcon}
                tickerText={pageData.counterText}
            />
            <OurMessage
                heading={pageData.ourMessageHeading}
                copy={pageData.ourMessageCopy.ourMessageCopy}
                businessTeam={businessTeam}
                image={pageData.ourMessageImage}
            />
            <FoundersStory
                heading={pageData.foundersHeading}
                copy={pageData.foundersCopy.foundersCopy}
                image={pageData.foundersImage}
            />
            <TheTeam
                heading={pageData.theTeamHeading}
                copy={pageData.theTeamCopy.theTeamCopy}
                ctaText={pageData.theTeamCta}
                jobLink={pageData.jobLink}
                image1={pageData.theTeamImage1}
                image2={pageData.theTeamImage2}
                image3={pageData.theTeamImage3}
            />
            <News
                heading={pageData.newsHeading}
                subheading={pageData.newsSubheading}
                blogs={pageData.blogs}
                ctaText={pageData.newsCtaText}
                ctaLink={pageData.newsCtaLink}
            />
            <WantToKnowMore
                faq={pageData.faqSection.faqs}
                title={pageData.faqSection.title}
                subtitle={pageData.faqSection.subtitle.subtitle}
            />
            <div className={styles.Spacer}></div>
            <Questions
                title={pageData.contactUsSection.title}
                content={pageData.contactUsSection.content.content}
                email={pageData.contactUsSection.email}
                phoneNumber={pageData.contactUsSection.phoneNumber}
                showApplyButton={pageData.contactUsSection.showApplyButton}
                showJobsButton={pageData.contactUsSection.showJobsButton}
                jobCTAText={pageData.jobCtaText}
                jobLink={pageData.jobLink}
                loanCTAText={pageData.loanCtaText}
            />
            <Footer />
        </div>
    );
};

export default AboutUs;

export const query = graphql`
    query AboutUsPageQuery($id: String!) {
        contentfulPageAboutUs(id: { eq: $id }) {
            titleTag
            metaDescription
            allowSearchEngineScraping
            url
            aboveTheFoldCopy {
                aboveTheFoldCopy
            }
            navigation {
                ... on Node {
                    ... on ContentfulNavbar {
                        name
                        menuItems {
                            name
                            item {
                                url
                                title
                            }
                        }
                    }
                    ... on ContentfulHeader {
                        ...Header
                    }
                }
            }
            aboveTheFoldHeading
            counterText
            counterIcon {
                fluid(maxWidth: 688, quality: 90) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            ourValues
            aboveTheFoldImage {
                fluid(maxWidth: 688, quality: 90) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            aboutUsTeam {
                ...aboutUsTeam
            }
            ourMessageHeading
            ourMessageCopy {
                ourMessageCopy
            }
            ourMessageImage {
                fluid(maxWidth: 688, quality: 90) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            foundersImage {
                fluid(maxWidth: 688, quality: 90) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            foundersHeading
            foundersCopy {
                foundersCopy
            }
            theTeamHeading
            theTeamCopy {
                theTeamCopy
            }
            theTeamCta
            theTeamImage1 {
                fluid(maxWidth: 888, quality: 90) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            theTeamImage2 {
                fluid(maxWidth: 688, quality: 90) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            theTeamImage3 {
                fluid(maxWidth: 688, quality: 90) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            blogs {
                ... on ContentfulBlogArticle {
                    id
                    name
                    slug
                    displayName
                    categoryTag {
                        categoryTag
                    }
                    articleHeaderImage {
                        fluid(maxWidth: 688, quality: 100) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                }
            }
            newsHeading
            newsSubheading
            newsCtaText
            newsCtaLink
            faqSection {
                ...faqSection
            }
            contactUsSection {
                ...contactUsSection
            }
            jobCtaText
            jobLink
            loanCtaText
        }
    }

    fragment faq on ContentfulFaq {
        question
        answer
        color
    }

    fragment aboutUsTeam on ContentfulAboutUsTeam {
        heading
        subheading
    }
`;
