import React from 'react';
import GatsbyImg from 'gatsby-image';
import styles from './FoundersStory.module.scss';
import ReactMarkdown from 'react-markdown';

const FoundersStory = ({ heading, copy, image }) => {
    return (
        <div className={styles.FoundersStory}>
            <div className={styles.LeftPane}>
                <div className={styles.ImageContainer}>
                    <GatsbyImg fluid={image.fluid} className={styles.image} />
                </div>
            </div>
            <div className={styles.RightPane}>
                <div className={styles.ContentContainer}>
                    <h2>{heading}</h2>
                    <div className={styles.Divider}></div>

                    <ReactMarkdown source={copy} />
                </div>
            </div>
        </div>
    );
};

export default FoundersStory;
